/* RESET */
body {
  background-color: #fff;
  font-family: 'Moderat', Helvetica, Arial, Lucida, sans-serif !important;
  line-height: 24px;
  font-size: 16px;
}

h1,
h2,
h3,
h5,
h6 {
  line-height: initial;
  margin-block-start: 0;
  margin-block-end: 0;
  color: white;

  font-family: 'Moderat', Helvetica, Arial, Lucida, sans-serif !important;
}

#sh-loggedin {
  display: flex;
  position: relative !important;
  right: -150px;
  top: -18px;
}

#sh-loggedin svg {
  width: 25px;
  height: 25px;
}

.websit-icon {
  width: 10px;
  position: relative !important;
  right: 80px !important;
  block-size: 1rem;
  inline-size: 1rem;
  padding: 0.25rem;
  clip-rule: evenodd;
  display: block;
  fill: inherit;
  fill-rule: evenodd;
  overflow: hidden;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

svg {
  clip-rule: evenodd;
  display: block;
  fill-rule: evenodd;
}

input,
textarea,
select {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: #333;
  display: block;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  padding: 0;
  width: 100%;
}

button {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  padding: 0;
}

/* OVERRIDES TO REVIST */
.article {
  padding: 0 !important;
  margin-block-end: 0 !important;
}

/* HEADER */
.header {
  background-color: white;
  z-index: -1;
}

/* NAV */
nav {
  line-height: 23px;
  font-weight: 500;
  background-color: #fff;
  box-shadow: 0px 31px 40px -6px rgb(11 9 26 / 10%);
  width: 100%;
  box-sizing: border-box;
  z-index: 99999;
  display: block;
  position: fixed;
  height: 79px;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1120px;
  width: 80%;
}

.nav-details {
  align-items: center;
  display: flex;
}

.nav-logo {
  display: block;
}

.nav-logo-image {
  width: 119.262px;
  box-sizing: border-box;
  display: inline-block;
  height: 42.975px;
  max-height: 54%;
  max-width: 100%;
  position: fixed;
  top: 20px;
}

.nav-title {
  margin-inline-start: 16px;
}

.nav-desktop-menu {
  display: none;
  position: relative;
  top: auto;
  right: 200px;
}

a:-webkit-any-link {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.6);
}

.nav-desktop-menu-link {
  margin-inline-start: 16px;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 33px;
  position: relative;
  top: 20px;
  right: 100px;
  transition: all 0.4s ease-in-out;
  font-weight: 600;
  -webkit-transition: all 0.4s ease-in-out;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background: transparent;
  word-wrap: break-word;
  line-height: 1em;
}

.nav-desktop-menu-link:hover {
  color: #000;
  height: auto;
}

#login {
  margin-inline-start: 16px;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 33px;
  display: block;
  position: relative;
  left: 50px;
  bottom: 20px;
  top: 0px;
  transition: all 0.4s ease-in-out;
  font-weight: 600;
  -webkit-transition: all 0.4s ease-in-out;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background: transparent;
  word-wrap: break-word;
  line-height: 1em;
}

#login:hover {
  color: #000;
  height: auto;
}

div.mobile-menu {
  position: relative;
  z-index: 10;
  background: transparent;
}

.nav-mobile-menu {
  display: flex;
}

.nav-mobile-menu-trigger,
.nav-mobile-search-trigger {
  fill: blue;
  padding: 2px;
  margin-inline-start: 8px;
}

.nav-mobile-menu-trigger:hover,
.nav-mobile-search-trigger:hover {
  fill: blue;
}

.nav-mobile-menu-trigger:active,
.nav-mobile-search-trigger:active {
  fill: blue;
}

@media screen and (min-width: 1024px) {
  .nav-desktop-menu {
    align-items: center;
    display: flex;
    -webkit-font-smoothing: antialiased;
    padding-bottom: 33px;
    color: rgba(0, 0, 0, 0.6) !important;
    text-decoration: none;
    display: block;
    position: relative;
    transition: all 0.4s ease-in-out;
    -webkit-text-size-adjust: 100%;

    background: transparent;
  }

  .nav-mobile-menu {
    display: none;
  }
}

/* LANGUAGE PICKER */
.language-picker {
  color: #333;
  fill: #666;
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 32px;
  position: relative;
}

.language-picker-input {
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  padding: 8px;
  position: relative;
}

.language-picker-input::before {
  border: thin solid #ccc;
  border-radius: inherit;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

.language-picker-image {
  border-radius: 3px;
  margin-inline-end: 4px;
  width: 20px;
}

.language-picker-name {
  flex-grow: 1;
  padding-inline-start: 4px;
  padding-inline-end: 4px;
}

.language-picker-icon {
  flex-shrink: 0;
  height: 16px;
  margin-inline-start: 4px;
  padding: 2px;
  width: 16px;
}

.language-picker-item .language-picker-icon {
  position: absolute;
  right: 16px;
  top: 8px;
}

.language-picker-overlay {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.language-picker-list {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: thin solid #ccc;
  border-radius: 6px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  max-height: calc(200px);
  overflow: auto;
  position: absolute;
  padding-block-start: 8px;
  padding-block-end: 8px;
  padding-inline-start: 0;
  right: 0;
  top: calc(100% + 4px);
  white-space: nowrap;
  z-index: 2;
}

.language-picker-item {
  align-items: center;
  color: #333;
  cursor: pointer;
  display: flex;
  fill: #666;
  padding: 8px 52px 8px 16px;
  position: relative;
}

.language-picker-item:hover {
  background-color: #f7f7f7;
}

@media screen and (min-width: 1024px) {
  .language-picker {
    margin-block-start: 0;
    margin-inline-start: 16px;
  }
}

@media only screen and (max-width: 720px) {
  footer {
    margin-top: 1000px !important;
    width: auto;
  }

  div.mobile-menu {
    background: transparent;
  }

  .mobile-menu-link,
  #login {
    line-height: 30px;
    font-weight: 400;
    display: block;
    text-align: center;
    font-size: 18px;
    position: relative;
    top: 100px;
    left: 0;
  }

  .mobile-menu-link:hover {
    color: #000;
    height: auto;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 720px) {
  footer {
    margin-top: 550px !important;
    width: auto;
  }
}

/* HERO */
.hero {
  font-family: 'Moderat', Helvetica, Arial, Lucida, sans-serif !important;
  background-image: linear-gradient(60deg, #006fff 0%, #cde3ff 77%) !important;
  padding-top: 100px;
  padding-right: 0px;
  padding-bottom: 1px;
  padding-left: 0px;
  height: 450px;
  position: relative;
}

.hero-wrapper {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1120px;
  padding-block-start: 32px;
  padding-block-end: 64px;
  text-align: center;
  width: 80%;
}

.hero-search {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.16666 3.33341C5.945 3.33341 3.33332 5.94509 3.33332 9.16675C3.33332 12.3884 5.945 15.0001 9.16666 15.0001C10.7777 15.0001 12.2349 14.3481 13.2914 13.2915C14.348 12.235 15 10.7778 15 9.16675C15 5.94509 12.3883 3.33341 9.16666 3.33341ZM1.66666 9.16675C1.66666 5.02461 5.02452 1.66675 9.16666 1.66675C13.3088 1.66675 16.6667 5.02461 16.6667 9.16675C16.6667 10.9373 16.0524 12.5654 15.0265 13.8481L18.0892 16.9108C18.4147 17.2363 18.4147 17.7639 18.0892 18.0893C17.7638 18.4148 17.2362 18.4148 16.9107 18.0893L13.848 15.0266C12.5653 16.0525 10.9372 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3089 1.66666 9.16675Z' fill='%2374808A'/%3E%3C/svg%3E%0A");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: thin solid #ccc;
  border-radius: 6px;
  display: inline-block;
  padding: 16px 24px 16px 44px;
  margin-block-start: 32px;
  max-width: 400px;
}

/* MOBILE MENU */
.mobile-menu-wrapper {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1120px;
  padding-block-end: 10px;
  width: 80%;
}

.mobile-menu-link {
  display: block;
  text-align: center;
}

.mobile-menu-link+.mobile-menu-link {
  margin-block-start: 32px;
}

@media screen and (min-width: 1024px) {
  .mobile-menu {
    display: none;
  }
}

/* SEARCH */
.mobile-search {
  background-color: white;
}

.mobile-search-wrapper {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1120px;

  padding-block-end: 32px;
  width: 80%;
}

.mobile-search-input {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.16666 3.33341C5.945 3.33341 3.33332 5.94509 3.33332 9.16675C3.33332 12.3884 5.945 15.0001 9.16666 15.0001C10.7777 15.0001 12.2349 14.3481 13.2914 13.2915C14.348 12.235 15 10.7778 15 9.16675C15 5.94509 12.3883 3.33341 9.16666 3.33341ZM1.66666 9.16675C1.66666 5.02461 5.02452 1.66675 9.16666 1.66675C13.3088 1.66675 16.6667 5.02461 16.6667 9.16675C16.6667 10.9373 16.0524 12.5654 15.0265 13.8481L18.0892 16.9108C18.4147 17.2363 18.4147 17.7639 18.0892 18.0893C17.7638 18.4148 17.2362 18.4148 16.9107 18.0893L13.848 15.0266C12.5653 16.0525 10.9372 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3089 1.66666 9.16675Z' fill='%2374808A'/%3E%3C/svg%3E%0A");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border: thin solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  top: 100px;
  padding: 12px 16px 12px 40px;
}

@media screen and (min-width: 1024px) {
  .mobile-search {
    display: none;
  }
}

/* SEARCH - EMPTY */
.search-empty {
  text-align: center;
}

.search-empty-title {
  font-size: 32px;
  line-height: 48px;
}

.search-empty-message {
  font-size: 18px;
  line-height: 28px;
  margin-block-start: 16px;
}

/* BODY */
.body-wrapper {
  padding-block-start: 40px;
  padding-block-end: 40px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  min-height: 100%;
  margin-bottom: -50px;
  width: 80%;
  overflow: hidden;
}

/* GRID */
.grid {
  display: grid;
  gap: 1.5rem;
  position: absolute;
  top: 320px;
  width: 80%;
}

.grid-item {
  display: grid;
  align-items: center;
  position: relative;
  text-align: center;
  transition-property: box-shadow, transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0, 0, 0, 0.4);
  background-color: #fafafc !important;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 31px 40px -6px rgb(11 9 26 / 10%);
}

@media screen and (min-width: 720px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* CARD */
.card {
  position: relative;
  top: 0px;
  border-radius: 10px 10px 10px 10px;
  bottom: 30px;
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px 32px;
  text-align: center;
  text-decoration: none;
}

.card-icon {
  height: 80px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-block-end: 32px;
  width: 80px;
  background: transparent;
}

.card-title {
  color: #000;
  font-size: 22px;
}

.card-divider {
  background-color: #ccc;
  height: 1px;
  margin: 32px auto 16px auto;
  width: 64px;
}

.card-info {
  color: #666;
  margin-block-start: 16px;
}

main {
  height: 100%;
}

.grid-item:hover {
  transform: translate3d(0px, -0.25rem, 0px);
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 31px 40px -6px rgb(11 9 26 / 10%);
  transition-property: box-shadow, transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0, 0, 0, 0.4);

}

[isExpanded='true'] .vert {
  display: none;
}

/* Category List  */
.category-list-button {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
}

.category-list-button svg {
  position: relative;
  top: 1px;
}

.category-list-button:hover {
  opacity: 0.8;
}

.plus rect {
  transition: all 500ms;
  transform: scaleY(1);
  transform-origin: 50% 50%;
}

.plus.minus .vertical-line {
  transform: scaleY(0);
}

.vertical-line {
  fill: #006fff;
}

.horizontal-line {
  fill: #006fff;
}

.category-list-ul {
  list-style-type: none;
  padding-left: 0.25rem;
}

.category-list-button-text {
  margin: 1px;
  font-size: 1rem;
  padding: 0 0 0 1rem;
  text-align: left;
  font-weight: 540;
}

.article-header {
  color: rgba(0, 0, 0, 0.8) !important;
  position: relative;
  left: 40px;
  display: block;
  margin-block-start: 16px;
  font-size: 0.8rem;
  line-height: 1rem;
  list-style: none;
}

.article-header:hover {
  color: #006fff !important;
  opacity: 0.8;
  text-decoration: underline !important;
}

/* FOOTER */
footer {
  background-color: #1f1f1f !important;
  position: sticky;
  margin-top: 660px;
  height: 60px;
}

#terms {
  padding-top: 10px;
  color: #666 !important;
  font-weight: 300;
  font-family: 'Moderat', Helvetica, Arial, Lucida, sans-serif;
  line-height: 1.7em;
  font-size: 100%;
}

#terms:hover {
  color: #006fff !important;
  text-decoration: underline !important;
}

#footer-info {
  padding-top: 5px !important;
  margin: 0;
  margin-left: 90px;

  padding: 0;
  border: 0;
  outline: 0;
  font-size: 90%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  font-family: 'Moderat', Helvetica, Arial, Lucida, sans-serif;
  line-height: 1.7em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  min-height: 100%;
  margin: 0;
}

.body {
  min-height: 100%;
  margin-bottom: -50px;
  width: 100%;
  margin: 0;
  display: table;
}

/* WITH ASIDE */
.main {
  margin-block-end: 32px;
}

.aside {
  display: grid;
  row-gap: 32px;
}

@media screen and (min-width: 720px) {
  .main-centered {
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: 66.666666%;
  }
}

@media screen and (min-width: 1024px) {
  .with-aside {
    align-items: start;
    column-gap: 32px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    margin-bottom: 50px;
    position: relative;
    top: 10px;
  }

  .aside {
    grid-column-start: 9;
    grid-column-end: 13;
    grid-row-start: 1;
    padding-inline-start: 32px;
  }

  .main {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    margin-block-end: 0;
  }
}

/* MAIN */
.main-header {
  margin-block-end: 32px;
}

.main-content-card {
  position: relative;
  box-shadow: 0px 2px 18px 0px rgb(198 198 198 / 30%);
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  border-width: 0px;
  border-style: solid;
  border-color: #efefef;
  background-color: #ffffff;
}

.main-title {
  color: black;
  /*ont-size: 2.25rem; */
  line-height: 2.75rem;
}

@media screen and (min-width: 1024px) {
  .main-content-card {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 8px 32px rgba(0, 0, 0, 0.08);
    padding: 32px;
  }
}

/* ARTICLE ACTIONS */
.article-actions {
  display: flex;
  margin-block-start: 16px;
  margin-block-end: 16px;
}

.article-actions-button {
  fill: #666;
  padding: 8px;
  position: relative;
}

.article-actions-button:hover {
  fill: #333;
}

.article-actions-icon {
  height: 16px;
  padding: 2px;
  width: 16px;
}

.article-actions-tooltip {
  background-color: #333;
  border-radius: 4px;
  bottom: 100%;
  color: #fff;
  font-size: 14px;
  left: 50%;
  padding: 4px 12px;
  position: absolute;
  transform: translate3d(-50%, 0, 0);
}

.article-actions-tooltip::before {
  border-color: #333 transparent transparent transparent;
  border-style: solid;
  border-width: 4px;
  content: '';
  display: block;
  left: calc(50% - 4px);
  position: absolute;
  top: 100%;
}

@media screen and (min-width: 1024px) {
  .article-actions {
    margin-block-start: 0;
    margin-block-end: 0;
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.article {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

/* SIDEBAR SEARCH */
.sidebar-search {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.16666 3.33341C5.945 3.33341 3.33332 5.94509 3.33332 9.16675C3.33332 12.3884 5.945 15.0001 9.16666 15.0001C10.7777 15.0001 12.2349 14.3481 13.2914 13.2915C14.348 12.235 15 10.7778 15 9.16675C15 5.94509 12.3883 3.33341 9.16666 3.33341ZM1.66666 9.16675C1.66666 5.02461 5.02452 1.66675 9.16666 1.66675C13.3088 1.66675 16.6667 5.02461 16.6667 9.16675C16.6667 10.9373 16.0524 12.5654 15.0265 13.8481L18.0892 16.9108C18.4147 17.2363 18.4147 17.7639 18.0892 18.0893C17.7638 18.4148 17.2362 18.4148 16.9107 18.0893L13.848 15.0266C12.5653 16.0525 10.9372 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3089 1.66666 9.16675Z' fill='%2374808A'/%3E%3C/svg%3E%0A");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border: thin solid #ccc;
  border-radius: 6px;
  display: none;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 16px 12px 40px;
}

@media screen and (min-width: 1024px) {
  .sidebar-search {
    display: block;
  }
}

/* BREADCRUMBS */
.breadcrumbs {
  font-size: 14px;
  display: flex;
}

.breadcrumbs-header,
.category-beardcrumbs {
  font-size: 14px;
  display: flex;
  margin-top: 87px;
}

.breadcrumbs-link {
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  font-weight: 600;
  color: rgb(0, 0, 0) !important;
}

.breadcrumbs-link:hover {
  display: block;
  color: #006fff !important;
  text-decoration: underline !important;
  text-decoration: underline;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

.breadcrumbs-divider {
  color: #666;
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}

/* ARTICLE LINK */
.article-link {
  background-color: #cde3ff;
  color: inherit !important;
  display: flex;
  outline: none;
  padding: 24px 32px;
  text-decoration: none;
  border-radius: 10px 10px 10px 10px;
}

.article-link+.article-link {
  margin-block-start: 32px;
}

.article-link:hover {
  background-color: #f7f7f7;
}

.article-link-breadcrumbs {
  margin-block-end: 8px;
}

.article-link-title {
  color: black;
  font-size: 1.125rem;
  line-height: 1.75rem;

}

.article-link-title:hover {
  text-decoration: underline !important;
  color: #006fff !important;
}

.article-link-image {
  flex-shrink: 0;
  height: 80px;
  width: auto;
}

.article-link-summary {
  color: #333;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-block-start: 8px;
}

/* ARTICLE META */
.article-meta {
  color: #333;
  fill: #333;
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 16px;
}

.article-meta-item {
  display: flex;
}

.article-meta-item+.article-meta-item {
  margin-block-start: 8px;
}

.article-meta-icon {
  height: 12px;
  padding: 4px;
  width: 12px;
}

.article-meta-label {
  margin-inline-start: 4px;
}

/* ARTICLE/CATEGORY LIST */
#categories-text {
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #1f1f1f;
}

#article-text {
  color: #1f1f1f;
}

.article-list,
.category-list {
  font-size: 14px;
  line-height: 20px;
  list-style: none;
  margin-block-start: 16px;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.category-list-item {
  margin-block-start: 8px;
  font-size: 1rem;
  line-height: 1.5rem;
}

.article-list-item {
  display: block;
  margin-block-start: 16px !important;
  font-size: 0.8rem;
  line-height: 1rem;
}

.article-list-txt:active {
  color: #006fff !important;
}

.article-list-link,
.category-list-link {
  color: #333 !important;
}

.article-list-link:hover,
.category-list-link:hover {
  color: #006fff !important;
  text-decoration: underline !important;
}

.category-page,
.article-page {
  background-image: #f7f7f7 !important;
}

.article-list-link:active,
.category-list-link:active {
  color: #006fff !important;
  text-decoration: underline !important;
}

/* SHOW MORE */
.show-more {
  background-color: #f7f7f7;
  font-weight: 500;
  margin-block-start: 32px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding: 16px 20px;
}

.show-more:disabled {
  opacity: 0.5;
}

/* ERROR */
.error {
  text-align: center;
}

.error-title {
  font-size: 56px;
  line-height: 64px;
}

.error-message {
  font-size: 18px;
  line-height: 28px;
  margin-block-start: 24px;
}

.error-search {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.16666 3.33341C5.945 3.33341 3.33332 5.94509 3.33332 9.16675C3.33332 12.3884 5.945 15.0001 9.16666 15.0001C10.7777 15.0001 12.2349 14.3481 13.2914 13.2915C14.348 12.235 15 10.7778 15 9.16675C15 5.94509 12.3883 3.33341 9.16666 3.33341ZM1.66666 9.16675C1.66666 5.02461 5.02452 1.66675 9.16666 1.66675C13.3088 1.66675 16.6667 5.02461 16.6667 9.16675C16.6667 10.9373 16.0524 12.5654 15.0265 13.8481L18.0892 16.9108C18.4147 17.2363 18.4147 17.7639 18.0892 18.0893C17.7638 18.4148 17.2362 18.4148 16.9107 18.0893L13.848 15.0266C12.5653 16.0525 10.9372 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3089 1.66666 9.16675Z' fill='%2374808A'/%3E%3C/svg%3E%0A");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: thin solid #ccc;
  border-radius: 6px;
  display: inline-block;
  padding: 16px 24px 16px 44px;
  margin-block-start: 24px;
  max-width: 400px;
}

/* ACCESS BANNER */
.access-banner {
  border: thin solid #ccc;
  border-radius: 6px;
  margin-block-start: 32px;
  overflow: hidden;
}

.access-banner-header {
  background-color: #f7f7f7;
  display: flex;
  padding: 16px;
}

.access-banner-icon {
  flex-shrink: 0;
  height: 16px;
  padding: 4px;
  width: 16px;
}

.access-banner-title {
  flex-grow: 1;
  font-weight: 500;
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}

.access-banner-toggle {
  flex-shrink: 0;
}

.access-banner-body {
  border-top: thin solid #ccc;
  list-style: none;

  margin-block-start: 0;
  margin-block-end: 0;
  padding: 16px 24px;
}

.access-banner-group+.access-banner-group {
  margin-block-start: 4px;
}

/* ALERT */
.alert {
  animation-name: alert;
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  background-color: #333;
  border-radius: 6px;
  bottom: 0;
  color: #fff;
  display: flex;
  fill: #fff;
  font-size: 14px;
  left: 50%;
  line-height: 20px;
  padding: 16px;
  position: fixed;
  transform: translate3d(-50%, -32px, 0);
  z-index: 1;
}

.alert-error {
  background-color: #600;
}

.alert-success {
  background-color: #060;
}

.alert-message {
  padding-inline-start: 4px;
  padding-inline-end: 4px;
}

.alert-close {
  flex-shrink: 0;
  margin-inline-start: 4px;
}

.alert-icon {
  height: 16px;
  padding: 2px;
  width: 16px;
}

@keyframes alert {
  0% {
    opacity: 0;
    transform: translate3d(-50%, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(-50%, -32px, 0);
  }
}

/* ARTICLE FEEDBACK */
.article-feedback {
  border-block-start: thin solid #eee;
  margin-block-start: 32px;
  margin-inline-start: -32px;
  margin-inline-end: -32px;
  padding-block-start: 32px;
  padding-inline-start: 32px;
  padding-inline-end: 32px;
  text-align: center;
}

.article-feedback-title {
  margin-bottom: 32px;

  font-size: 14px;
  font-weight: 400;

  color: rgba(0, 0, 0, 0.8);
}

* {
  font-family: 'Moderat', Helvetica, Arial, Lucida, sans-serif !important;
}

.article-feedback-options {
  display: flex;
  justify-content: center;
  margin-block-start: 16px;
}

.article-feedback-button {
  background-color: #006fff;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
}

.article-feedback-button.secondary {
  background-color: #eee;
  color: #333;
}

.article-feedback-button+.article-feedback-button {
  margin-inline-start: 8px;
}

.article-feedback-input {
  border: thin solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 16px;
  max-width: 100%;
  min-width: 100%;
  padding: 8px 12px;
}

.article-feedback-input:focus {
  border-color: #666;
}

.article-feedback-input.invalid {
  background-color: #fee;
  border-color: #600;
  color: #300;
}

.article-feedback-actions {
  display: flex;
  margin-block-start: 32px;
}

.article-feedback-message {
  color: #900;
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 8px;
  text-align: start;
}

/* PAGE LOADER */
.page-loader {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* LOADING BAR */
.loading-bar {
  position: fixed;
  height: 2px;
  top: 0;
  font-family: Open Sans, Arial, sans-serif;
  font-size: 14px;
  color: #666 !important;
  background-color: #fff;
  line-height: 1.7em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  transition-property: opacity, transform, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  width: 100%;
  will-change: opacity, transform, visibility;
}

.loading-bar.hidden {
  opacity: 0;
  visibility: hidden;
}